import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class BaseServiceContextService {
  private readonly _baseUrl: string;

  constructor(public http: HttpClient) {
    this._baseUrl = environment.apis.url;
  }

  public get baseUrl(): string {
    return this._baseUrl;
  }

  // public convertBodyToFormData(obj: object): FormData {
  //   return Object.keys(obj).reduce((formData, key) => {
  //     if (Array.isArray(obj[key])) {
  //       _.forEach(obj[key], (item) => {
  //         formData.append(`${key}[]`, item);
  //       });
  //     } else {
  //       formData.append(key, obj[key]);
  //     }
  //     return formData;
  //   }, new FormData());
  // }

  // public createHttpHeader() {
  //   let headers = new HttpHeaders();
  //   headers = headers.append('skipContentType', 'true');
  //   return headers;
  // }
}
