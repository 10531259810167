import {eventReducer} from "../main/event/event_state/event.reducer";
import { gridReducer} from "../main/event/grid/grid_state/grid.reducer";
import {EventState} from "../main/event/event_state/event.state";
import {GridState} from "../main/event/grid/grid_state/grid.state";

export interface AppState {
  user: any;
  events: EventState,
  grids: GridState,

}

export const appReducer = {
  events: eventReducer,
  grids: gridReducer
}
