import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {CommonModule} from "@angular/common";
import {BrowserModule} from "@angular/platform-browser";
import {ErrorComponent} from "./shared/components/error/error.component";
import {NotFoundComponent} from "./shared/components/not-found/not-found.component";
import {AuthGuard} from "./guard/auth-guard";
import {LoginGuard} from "./guard/login-guard";
import {CheckRouterComponent} from "./components/check-router/check-router.component";

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./main/main.module').then((m) => m.MainModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'account',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'check-user',
    component: CheckRouterComponent,
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: 'login',
    redirectTo: 'account',
    pathMatch : 'full',
  },
  {
    path: '**',
    component: NotFoundComponent,
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
