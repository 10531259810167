import {Injectable} from '@angular/core';
import {BehaviorSubject, map, noop, Observable, switchMap, tap, throwError} from "rxjs";
import {BaseServiceContextService} from "./base-service-context.service";
import {environment} from "../../../environments/environment";
import {RefreshResponse, User, VerificationResponse} from "../../auth/user.model";
import {catchError} from "rxjs/operators";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  // @ts-ignore
  private _user: UserI;
  private _user$: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  private token: BehaviorSubject<VerificationResponse | null>;

  constructor(private baseServiceContext: BaseServiceContextService,
              private router: Router) {
    this.token = new BehaviorSubject<VerificationResponse | null>(null);
  }

  public get getToken(): VerificationResponse | null {
    return this.token.value;
  }

  public setTokenInStorageAndLocal(user: VerificationResponse): void {
    localStorage.setItem(environment.tokenName, JSON.stringify(user));
    this.setToken(user);
  }

  public setToken(tokenValue: VerificationResponse): void {
    this.token.next(tokenValue)
  }

  public fetchCurrentUserIfNotExist(): void {
    if (this.isUserExist()) {
      return;
    } else {
      this.fetchCurrentUserAndUpdate().subscribe(noop);
    }
  }

  public selectCurrentUser(): Observable<User | null> {
    return this._user$;
  }

  public fetchCurrentUser(): Observable<User> {
    return this.baseServiceContext.http.get<User>(`${this.baseServiceContext.baseUrl}/rest-auth/user/`)
  }

  public readToken(): void {
    let token;
    try {
      token = JSON.parse(localStorage.getItem(environment?.tokenName)!)
    } catch {
      token = ''
    }
    this.token.next(token)
  }

  public verifyToken(): Observable<boolean> {
    const body = {
      token: this.getToken?.access_token
    };

    return this.baseServiceContext.http.post<boolean>(`${this.baseServiceContext.baseUrl}/rest-auth/token/verify/`, body)
  }

  public refreshToken() {
    const body = {
      refresh: this.getToken?.refresh_token
    };

    return this.baseServiceContext.http
      .post<RefreshResponse>(`${this.baseServiceContext.baseUrl}/rest-auth/token/refresh/`, body)
      .pipe(
        switchMap(user => {
          localStorage.setItem(environment.tokenName, JSON.stringify({
            access_token: user.access,
            refresh_token: user.refresh
          }));
          this.setToken({access_token: user.access, refresh_token: user.refresh});

          // Fetch the updated user data after token refresh
          return this.fetchCurrentUser().pipe(
            tap(updatedUser => {
              this._user = updatedUser;
              this._user$.next(updatedUser);
            }),
            map(() => user)
          );
        }),
        catchError((error) => {
          if (error.error.code == "token_not_valid") {
            this.removeToken();
            this.removeUserData();
            this.router.navigate(['/account/login']);
          }
          return throwError(error);
        })
      );
  }

  public isUserExist(): boolean {
    return !!this._user;
  }

  public isUserLogin(): boolean {
    const token = localStorage.getItem(environment.tokenName);
    return !!token;
  }

  public removeUserData(): void {
    if (localStorage.getItem(environment.tokenName)) {
      localStorage.removeItem(environment.tokenName);
    }
    this._user = null;
    this._user$.next(null);
  }

  public fetchCurrentUserAndUpdate(): Observable<void | null> {
    return this.fetchCurrentUser().pipe(
      map((user) => {
        this._user = user;
        this._user$.next(user);
        return null;
      })
    );
  }

  public changePassword(body: any): Promise<any> {
    return this.baseServiceContext.http
      .post<void>(`${this.baseServiceContext.baseUrl}/rest-auth/password/change/`, body)
      .toPromise();
  }

  public editAccount(form: User): Promise<any> {
    return this.baseServiceContext.http
      .put<void>(`${this.baseServiceContext.baseUrl}/rest-auth/user/`, form)
      .toPromise();
  }

  public deleteAccount(form: User): Promise<any> {
    return this.baseServiceContext.http
      .post<void>(`${this.baseServiceContext.baseUrl}/delete-me/`, form)
      .toPromise();
  }

  public removeToken(): void {
    this.token.next(null)
  }

  public logout() {
    const formData = new FormData()
    formData.append('refresh', this.token.value?.refresh_token!);
    return this.baseServiceContext.http.post(this.baseServiceContext.baseUrl + '/rest-auth/logout/', formData)
      .pipe(tap(() => {
        this.removeToken();
        this.removeUserData();
      })).toPromise()
  }


}


