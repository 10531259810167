import {Injectable} from '@angular/core';
import {throwError} from "rxjs";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MessageBarService} from "./message-bar.service";


@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  constructor(private _snackBar: MessageBarService) {
  }

  errorHandlingWithStatus(errors: any) {
    this.handler(errors);
    return throwError(errors);
  }


  handler(errors: any) {
    let messages: string[] = [];
    if (errors.detail) {
      messages.push(`${errors.detail}`);
    } else {
      let payload = this._formatPayload(errors);
      for (let key in payload) {
        if (payload.hasOwnProperty(key)) {
          if (Array(payload[key])) {
            payload[key].forEach((error: any) => {
              messages.push(`${error}`);
            });

          } else {
            console.log('no there')
          }
        }
      }
    }

    // @ts-ignore
    this._snackBar.errorMessage(messages);
  }

  _formatPayload(payload: any) {
    for (let key in payload) {
      if (payload.hasOwnProperty(key)) {
        if (typeof payload[key] === 'string') {
          payload[key] = [payload[key]];
        }
      }
    }
    return payload;
  }
}
