<footer class="footer ">
    <div class="container-fluid">
        <nav class="pull-left">
            <ul>
                <li>
                    <a href="https://evocount.de/datenschutzerklaerung/">
                        Privacy Policy
                    </a>
                </li>
              <li>
                <a class="text-primary" href="mailto:info@evocount.de">
                  <mat-icon color="primary">email</mat-icon>
                  info@evocount.de</a>
              </li>
                <li>
                  <app-change-language-button></app-change-language-button>
                </li>

            </ul>
        </nav>
        <div class="copyright pull-right">
          EvoCount Eyes | Copyright &copy;
            {{test | date: 'yyyy'}},
            <a href="https://evocount.de/" target="_blank">EvoCount GmbH</a>.
        </div>
    </div>
</footer>
