export const environment = {

  production: true,
  mapbox: {
    accessToken: 'pk.eyJ1IjoidHRpbGwiLCJhIjoiY2szMDVucG1kMDBvNzNkbnprYzNjemZ5OCJ9.xXzdzH6oaBN07pypMs955w'
  },
  mapquest: {
    accessToken:""
  },
  apis: {
    url: 'https://api.dev.evocount.de'
  },
  website: {
    url: 'https://eyes.dev.evocount.de/'
  },
  tokenName: 'eyes-token'
};
