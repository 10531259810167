import { createReducer, on } from '@ngrx/store';
import { openEvent} from './event.actions';
import {EventState} from "./event.state";



export const initialOpenedEventState: EventState = {
  openedEvents: []
};

export const eventReducer = createReducer(
  initialOpenedEventState,
  on(openEvent, (state, { event }) => {
    const eventExists = state.openedEvents.some(e => e.id === event.id);
    return eventExists
      ? state
      : {
        ...state,
        openedEvents: [...state.openedEvents, event]
      };

  })
);
