
import {createReducer, on} from "@ngrx/store";

import * as GridActions from './grid.actions';
import {Pageable} from "../../../../shared/models/pageable";

export interface GridState {
  gridList: Pageable | null;
  grid: any;
  error: any;
  loading: boolean;
}

export const initialState: GridState = {
  gridList: null,
  grid: null,
  error: null,
  loading: false
};

export const gridReducer = createReducer(
  initialState,
  // Load grids
  on(GridActions.loadGrids, (state) => ({ ...state, loading: true })),
  on(GridActions.loadGridsSuccess, (state, { gridList }) => ({ ...state, loading: false, gridList })),
  on(GridActions.loadGridsFailure, (state, { error }) => ({ ...state, loading: false, error })),

  // Load grid by filter
  on(GridActions.loadGridByFilter, (state) => ({ ...state, loading: true })),
  on(GridActions.loadGridByFilterSuccess, (state, { gridList }) => ({ ...state, loading: false, gridList })),
  on(GridActions.loadGridByFilterFailure, (state, { error }) => ({ ...state, loading: false, error })),

  // Load grid by ID
  on(GridActions.loadGridById, (state) => ({ ...state, loading: true })),
  on(GridActions.loadGridByIdSuccess, (state, { grid }) => ({ ...state, loading: false, grid })),
  on(GridActions.loadGridByIdFailure, (state, { error }) => ({ ...state, loading: false, error })),

  // Create grid
  on(GridActions.createGrid, (state) => ({ ...state, loading: true })),
  on(GridActions.createGridSuccess, (state, { grid }) => ({ ...state, loading: false, grid })),
  on(GridActions.createGridFailure, (state, { error }) => ({ ...state, loading: false, error })),

  // Update grid
  on(GridActions.updateGrid, (state) => ({ ...state, loading: true })),
  on(GridActions.updateGridSuccess, (state, { grid }) => ({ ...state, loading: false, grid })),
  on(GridActions.updateGridFailure, (state, { error }) => ({ ...state, loading: false, error })),

  // Delete grid
  on(GridActions.deleteGrid, (state) => ({ ...state, loading: true })),
  // on(GridActions.deleteGridSuccess, (state, { id }) => ({
  //   ...state,
  //   loading: false,
  //   gridList: state.gridList?.results?.filter(grid => grid.id !== id) || null
  // })),
  on(GridActions.deleteGridFailure, (state, { error }) => ({ ...state, loading: false, error }))
);
