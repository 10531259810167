
<button mat-button (click)="changeLanguage()">
 <ng-container *ngIf="selectedLanguage == 'de'">
   <img src="./assets/img/united-kingdom.png"  height="25" alt="">
   EN
 </ng-container>
  <ng-container *ngIf="selectedLanguage == 'en'">
    <img src="./assets/img/germany.png"  height="25" alt="">
    DE
  </ng-container>
</button>
