import { Component } from '@angular/core';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";

@Component({
  selector: 'app-loading-full-screen',
  templateUrl: './loading-full-screen.component.html',
  styleUrls: ['./loading-full-screen.component.scss'],
  imports: [MatProgressSpinnerModule],
  standalone: true
})
export class LoadingFullScreenComponent {

}
