import { Component, OnInit } from '@angular/core';
import {UserService} from "../../shared/services/user.service";
import {Router} from "@angular/router";

declare interface RouteInfo {
    path?: string;
    title: string;
    icon: string;
    class: string;
   children?: RouteInfo[]
}
export const ROUTES: RouteInfo[] = [
    { path: '/events', title: 'Home',  icon: 'dashboard', class: '' },
    { path: '/organization', title: 'Organizations',  icon:'groups', class: '' },
    { path: '/device', title: 'Devices',  icon:'device_hub', class: '' },
    { path: '/profile', title: 'Account',  icon:'person', class: '' }
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: RouteInfo[] | undefined;

  constructor(private userService: UserService, private router: Router) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
      return window.innerWidth <= 991;

  };

  logout() {
    this.userService.logout().then(() => this.router.navigate(['/account/login']));
  }
}
