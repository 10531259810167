import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';

import {Observable} from 'rxjs';
import {UserService} from "../shared/services/user.service";


@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(protected userService: UserService,
              protected router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = this.userService.isUserLogin();
    if (!token) {
      this.router.navigate(['/account/login']);
      return false;
    }
    return true;
  }
}



