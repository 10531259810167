import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, filter, Observable, switchMap, take, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ErrorHandlerService} from "../shared/services/error-handler.service";
import {UserService} from "../shared/services/user.service";


@Injectable({providedIn: 'root'})
export class ErrorInterceptor implements HttpInterceptor {

  private readonly whitelist = [/token\/refresh/, /token\/verify/];
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null)

  constructor(private errorService: ErrorHandlerService,
              private userService: UserService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (this.whitelist.some((wl) => wl.test(request.url))) return throwError(error);

        if (error.status === 401) {

          if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            return this.userService.refreshToken().pipe(
              switchMap((newToken) => {
                this.isRefreshing = false;
                this.refreshTokenSubject.next(newToken);


                const token = this.userService.getToken?.access_token;
                const language = localStorage.getItem('eyesUserLocale') || 'en';

                const updatedRequest = {
                  Accept: 'application/json',
                  'accept-language': language,
                  Authorization: 'Bearer ' + token
                }

                const req = request.clone({
                  setHeaders: updatedRequest
                });

                return next.handle(req)
              })
            )
          } else {
            return this.refreshTokenSubject.pipe(filter((token) => token !== null), take(1), switchMap((_token) => {
              const token = this.userService.getToken?.access_token;
              const language = localStorage.getItem('eyesUserLocale') || 'en';

              const updatedRequest = {
                Accept: 'application/json',
                'accept-language': language,
                Authorization: 'Bearer ' + token
              }

              const req = request.clone({
                setHeaders: updatedRequest
              });

              return next.handle(req)
            }))
          }
        }
        return this.errorService.errorHandlingWithStatus(error.error);
      })
    );
  }


}
