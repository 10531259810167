<div class="logo">
    <a routerLink="/" class="simple-text ">
        <div class="logo-img logo-normal">
            <img src="/assets/img/app-logo.png"/>
        </div>
<!--      <div class="logo-img logo-mini">-->
<!--        <img src="/assets/img/app-icon.png"/>-->
<!--      </div>-->
    </a>

</div>
<div class="sidebar-wrapper">
  <div *ngIf="isMobileMenu()">
    <form class="navbar-form">
      <span class="bmd-form-group">
        <div class="input-group no-border">
          <input type="text" value="" class="form-control" placeholder="Search...">
          <button type="submit" class="btn btn-white btn-round btn-just-icon">
            <i class="material-icons">search</i>
            <div class="ripple-container"></div>
          </button>
        </div>
      </span>
    </form>
    <ul class="nav navbar-nav nav-mobile-menu">
<!--        <li class="nav-item">-->
<!--            <a class="nav-link" href="javascript:void(0)">-->
<!--                <i class="material-icons">dashboard</i>-->
<!--                <p>-->
<!--                    <span class="d-lg-none d-md-block">Stats</span>-->
<!--                </p>-->
<!--            </a>-->
<!--        </li>-->
        <li class="nav-item dropdown">
            <a class="nav-link" href="javascript:void(0)" id="notification" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="material-icons">notifications</i>
                <span class="notification">5</span>
                <p>
                    <span class="d-lg-none d-md-block">Some Actions</span>
                </p>
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="notification">
                <a class="dropdown-item" href="#">Mike John responded to your email</a>
                <a class="dropdown-item" href="#">You have 5 new tasks</a>
                <a class="dropdown-item" href="#">You're now friend with Andrew</a>
                <a class="dropdown-item" href="#">Another Notification</a>
                <a class="dropdown-item" href="#">Another One</a>
            </div>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="javascript:void(0)">
                <i class="material-icons">person</i>
                <p>
                    <span class="d-lg-none d-md-block">Account</span>
                </p>
            </a>
        </li>
    </ul>
  </div>
    <ul class="nav">
        <li routerLinkActive="active" *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item">
<!--          <ng-container *ngIf="!menuItem?.children?.length">-->
            <a  class="nav-link" [routerLink]="[menuItem?.path]">
              <i class="material-icons">{{menuItem.icon}}</i>
              <p>{{menuItem.title}}</p>
            </a>
<!--          </ng-container>-->
<!--          <ng-container *ngIf="menuItem?.children?.length">-->
<!--            <a data-toggle="collapse" class="nav-link collapsed" href="#{{menuItem.title}}" aria-expanded="false">-->
<!--                <i class="material-icons">{{menuItem.icon}}</i>-->
<!--              <p>{{menuItem.title}}<b class="caret"></b></p></a>-->
<!--            <div class="collapse" [id]="menuItem.title" style="">-->
<!--              <ul class="nav" >-->
<!--                <li routerlinkactive="active" class="nav-item" *ngFor="let subMenu of menuItem?.children">-->
<!--                  <a class="nav-link"  [routerLink]="[subMenu?.path]">-->
<!--                    <i class="material-icons">{{subMenu.icon}}</i>-->
<!--                    <p>{{subMenu.title}}</p>-->
<!--                  </a>-->
<!--                </li>-->
<!--              </ul>-->
<!--            </div>-->
<!--          </ng-container>-->
        </li>
      <li class="active-pro nav-item" (click)="logout()">
        <a  class="nav-link cursor-pointer">
          <i class="material-icons">logout</i>
          <p>Logout</p>
        </a>
      </li>
    </ul>
</div>
