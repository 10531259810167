import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import {
  ChangeLanguageButtonComponent
} from "../shared/components/change-language-button/change-language-button.component";
import {MatIconModule} from "@angular/material/icon";
import { CheckRouterComponent } from './check-router/check-router.component';
import {LoadingFullScreenComponent} from "../shared/components/loading-full-screen/loading-full-screen.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ChangeLanguageButtonComponent,
        MatIconModule,
        LoadingFullScreenComponent,
    ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    CheckRouterComponent
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent
  ]
})
export class ComponentsModule { }
