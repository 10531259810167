import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UserService} from "../shared/services/user.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly whitelist = [/\/assets/, /\/auth/, /\/common\/externals/];

  constructor(private userService: UserService) {
  }

  public intercept(request: HttpRequest<never>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.whitelist.some((wl) => wl.test(request.url))) return next.handle(request);

    const token = this.userService.getToken?.access_token;
    const language = localStorage.getItem('eyesUserLocale') || 'en';
    const headers = {
      Accept: 'application/json',
      'accept-language': language,
      Authorization: 'Bearer ' + token
    };
    if (!token) {
      // @ts-ignore
      delete headers.Authorization;
    }

    const req = request.clone({
      setHeaders: headers
    });
    return next.handle(req);
  }
}
