import { Component } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {UserService} from "../../shared/services/user.service";

@Component({
  selector: 'app-check-router',
  templateUrl: './check-router.component.html',
  styleUrls: ['./check-router.component.scss']
})
export class CheckRouterComponent {

  routerData!: RouterData;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private userService: UserService
  ) {
  }

  ngOnInit() {
    this.routerData = this.route.snapshot.queryParams as RouterData;
    this.userService.setTokenInStorageAndLocal({
      refresh_token: this.routerData.refresh_token,
      access_token: this.routerData.access_token!
    })

    this.userService.verifyToken().subscribe({
      next: () => {
        this.readDataAndNavigated();
      },
      error: () => {
        this.userService.removeUserData();
        this.userService.removeToken();
        this.router.navigate(['/account/login']);
      }
    });
  }

  private readDataAndNavigated() {
    let url = ''
    let params = {}
    switch (this.routerData.destination) {

      case 'analysis':
        const eventId = this.routerData['event'];
        url = `event/${eventId}/analysis`;
        params = {
          selected: this.routerData['analysis']
        }
        break;
    }

    this.router.navigate([url], {queryParams: params})
  }

}

interface RouterData {
  access_token: string
  refresh_token: string;
  destination: string

  [key: string]: string

}

// http://localhost:4200/check-user?destination=analysis&event=749&analysis=5862&access_token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjkyMzYzNjYxLCJqdGkiOiJmODcxZDI5Mjg2YjQ0ZTMxYjI4ZjRhYmVmNDliY2I4ZSIsInVzZXJfaWQiOjE3NTd9.-AH6LQqVsW9M-8tcnmNAGgCxZUTwD2XK8g-mANmQ1BE



