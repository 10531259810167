import { Component } from '@angular/core';
import {MatMenuModule} from "@angular/material/menu";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {NgIf} from "@angular/common";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-change-language-button',
  templateUrl: './change-language-button.component.html',
  styleUrls: ['./change-language-button.component.scss'],
  imports: [
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    NgIf
  ],
  standalone: true
})
export class ChangeLanguageButtonComponent {
constructor(private translate: TranslateService) {
}
  selectedLanguage: string = localStorage.getItem('eyesUserLocale') || 'en';

  changeLanguage() {
    this.selectedLanguage = this.selectedLanguage === 'en'? 'de' : 'en';
    this.useLanguage(this.selectedLanguage);
  }

  useLanguage(language: string): void {
    this.translate.use(language);
    localStorage.setItem('eyesUserLocale', language);
  }
}
