import {createAction, props} from "@ngrx/store";
import {Pageable} from "../../../../shared/models/pageable";
import {Params} from "../../../../shared/models/params";


// Actions for loading grid data
export const loadGrids = createAction(
  '[Grid] Load Grids',
  props<{ event: string }>()
);

export const loadGridsSuccess = createAction(
  '[Grid] Load Grids Success',
  props<{ gridList: Pageable }>()
);

export const loadGridsFailure = createAction(
  '[Grid] Load Grids Failure',
  props<{ error: any }>()
);

// Actions for loading grids by filter
export const loadGridByFilter = createAction(
  '[Grid] Load Grid By Filter',
  props<{ params: Params }>()
);

export const loadGridByFilterSuccess = createAction(
  '[Grid] Load Grid By Filter Success',
  props<{ gridList: Pageable }>()
);

export const loadGridByFilterFailure = createAction(
  '[Grid] Load Grid By Filter Failure',
  props<{ error: any }>()
);

// Actions for loading grid by ID
export const loadGridById = createAction(
  '[Grid] Load Grid By ID',
  props<{ id: number }>()
);

export const loadGridByIdSuccess = createAction(
  '[Grid] Load Grid By ID Success',
  props<{ grid: any }>()
);

export const loadGridByIdFailure = createAction(
  '[Grid] Load Grid By ID Failure',
  props<{ error: any }>()
);

// Actions for CRUD operations
export const createGrid = createAction(
  '[Grid] Create Grid',
  props<{ data: any }>()
);

export const createGridSuccess = createAction(
  '[Grid] Create Grid Success',
  props<{ grid: any }>()
);

export const createGridFailure = createAction(
  '[Grid] Create Grid Failure',
  props<{ error: any }>()
);

export const updateGrid = createAction(
  '[Grid] Update Grid',
  props<{ id: number, data: any }>()
);

export const updateGridSuccess = createAction(
  '[Grid] Update Grid Success',
  props<{ grid: any }>()
);

export const updateGridFailure = createAction(
  '[Grid] Update Grid Failure',
  props<{ error: any }>()
);

export const deleteGrid = createAction(
  '[Grid] Delete Grid',
  props<{ id: number }>()
);

export const deleteGridSuccess = createAction(
  '[Grid] Delete Grid Success',
  props<{ id: number }>()
);

export const deleteGridFailure = createAction(
  '[Grid] Delete Grid Failure',
  props<{ error: any }>()
);
